import React, { useState } from "react";
import { motion } from "framer-motion";
import {
  Card,
  Col,
  Container,
  Row,
  Form,
  Button,
  Spinner,
} from "react-bootstrap";
import axiosInstance from "../../utils/axiosUtil";
import { toast, ToastContainer } from "react-toastify";
import { getError } from "../../utils/error";

export default function CreateContactUs() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [desc, setDesc] = useState("");
  const [loading, setLoading] = useState(false);

  const resetForm = (e) => {
    setName("");
    setEmail("");
    setDesc("");
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    const contatData = {
      name,
      email,
      desc,
    };

    try {
      setLoading(true);
      const { data } = await axiosInstance.post(
        "/api/contact/create-contact",
        contatData
      );
      if (data.success) {
        setLoading(false);
        toast.success("Query sent successfully", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      resetForm();
    } catch (err) {
      setLoading(false);
      toast.error(getError(err), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
      exit={{ x: "100%" }}
    >
      <Container style={{ marginTop: "100px" }} className="px-5">
        <Row>
          <Col>
            <Card>
              <Card.Header as={"h6"}>Contact Us</Card.Header>
              <Form onSubmit={submitHandler}>
                <Card.Body>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      placeholder="Enter name"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      value={email}
                      placeholder="Enter email"
                      required
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="name">
                    <Form.Label>Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      value={desc}
                      onChange={(e) => setDesc(e.target.value)}
                      placeholder="Enter description"
                      required
                    />
                  </Form.Group>
                </Card.Body>
                <Card.Footer>
                  <Button variant="primary" type="submit">
                    {loading ? (
                      <Spinner animation="border" size="sm" />
                    ) : (
                      "Submit"
                    )}
                  </Button>
                </Card.Footer>
                <ToastContainer />
              </Form>
            </Card>
          </Col>
        </Row>
      </Container>
    </motion.div>
  );
}
