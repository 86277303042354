import React, { useEffect, useReducer, useContext, useState } from "react";
import { Store } from "../../states/store";
import { reducer } from "../../states/reducers";
import { useLocation, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { Card, Col, Container, Row } from "react-bootstrap";
import EditStoriesModel from "./EditStoriesModel.js";
import { FaEdit } from "react-icons/fa";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import { MessageBox } from "../../components";
import { getSingleEvent, getStreamedDetails } from "../../states/actions.js";
import moment from "moment";

const ViewStories = () => {
  const { state, dispatch: ctxDispatch } = useContext(Store);
  const { token, event, streamDetails } = state;
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const statuses = queryParams.get("status");
  const [modalShow, setModalShow] = useState(false);
  const [{ loading, error }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });

  useEffect(() => {
    getSingleEvent(ctxDispatch, dispatch, token, id);
    // if (statuses === "Completed") {
    //   getStreamedDetails(ctxDispatch, dispatch, token, id);
    // }
  }, [statuses, id]);

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Get day, month, year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Get hours and minutes
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Determine AM/PM suffix and adjust hours for 12-hour format
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return `${day}-${month}-${year} ${hours}:${minutes}${ampm}`;
  }

  function formatTime(timeString) {
    return moment(timeString, "HH:mm:ss").format("hh:mm A");
  }

  function formatDuration(durationString) {
    const [hours, minutes, seconds] = durationString.split(":").map(Number);

    const formattedHours = `${hours}h`;
    const formattedMinutes = `${minutes}m`;
    const formattedSeconds = `${seconds}s`;

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
      exit={{ x: "100%" }}
    >
      <Container fluid className="py-3">
        {error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <Card>
              <Card.Header>
                <Card.Title>
                  {loading ? <Skeleton /> : event?.title} Details
                </Card.Title>

                <div className="card-tools">
                  <FaEdit
                    style={{ color: "blue" }}
                    onClick={() => setModalShow(true)}
                  />
                </div>
              </Card.Header>
              <Card.Body>
                <Row className="mb-3">
                  <Col md={4}>
                    {loading ? (
                      <Skeleton height={200} />
                    ) : (
                      <img
                        style={{
                          borderRadius: "5px",
                        }}
                        src={event?.thumbnail}
                        alt=""
                        className="img-fluid"
                        width={"200px"}
                        height={"200px"}
                      />
                    )}
                  </Col>
                  <Col md={12}>
                    <Row>
                      <Col md={3}>
                        <p className="mb-0">
                          <strong>Event Name</strong>
                        </p>
                        <p>{loading ? <Skeleton /> : event?.title}</p>
                      </Col>
                      <Col md={3}>
                        <p className="mb-0">
                          <strong>Status</strong>
                        </p>
                        <p>{loading ? <Skeleton /> : event?.status}</p>
                      </Col>
                      <Col md={3}>
                        <p className="mb-0">
                          <strong>Host</strong>
                        </p>
                        <p>{loading ? <Skeleton /> : event?.host}</p>
                      </Col>
                      <Col md={3}>
                        <p className="mb-0">
                          <strong>Spots</strong>
                        </p>
                        <p>{loading ? <Skeleton /> : event?.spots}</p>
                      </Col>
                      <Col md={3}>
                        <p className="mb-0">
                          <strong>Entry Fees</strong>
                        </p>
                        <p>{loading ? <Skeleton /> : event?.entry_fee}</p>
                      </Col>
                      <Col md={3}>
                        <p className="mb-0">
                          <strong>Event Date</strong>
                        </p>
                        <p>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            event?.event_date && event?.event_date.split("T")[0]
                          )}
                        </p>
                      </Col>
                      <Col md={3}>
                        <p className="mb-0">
                          <strong>Event Duration</strong>
                        </p>
                        <p>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            formatDuration(event?.event_duration)
                          )}
                        </p>
                      </Col>
                      <Col md={3}>
                        <p className="mb-0">
                          <strong>Event Time</strong>
                        </p>
                        <p>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            formatTime(event?.event_time)
                          )}
                        </p>
                      </Col>

                      <Col md={3}>
                        <p className="mb-0">
                          <strong>Created At</strong>
                        </p>
                        <p>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            formatDate(event?.createdAt)
                          )}
                        </p>
                      </Col>
                      <Col md={3}>
                        <p className="mb-0">
                          <strong>Last Update</strong>
                        </p>
                        <p>
                          {loading ? (
                            <Skeleton />
                          ) : (
                            formatDate(event?.updatedAt)
                          )}
                        </p>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* {statuses === "Completed" && (
                  <Row className="mb-3">
                    <Col>
                      <strong>Stream Details</strong>
                      <hr />
                    </Col>
                    <Col md={12}>
                      <Row>
                        <Col md={3}>
                          <p className="mb-0">
                            <strong>Total Guest</strong>
                          </p>
                          <p>
                            {loading ? <Skeleton /> : streamDetails.totalGuest}
                          </p>
                        </Col>
                        <Col md={3}>
                          <p className="mb-0">
                            <strong>Event Likes</strong>
                          </p>
                          <p>{loading ? <Skeleton /> : streamDetails.likes}</p>
                        </Col>
                        <Col md={3}>
                          <p className="mb-0">
                            <strong>Event Disliked</strong>
                          </p>
                          <p>
                            {loading ? <Skeleton /> : streamDetails.dislikes}
                          </p>
                        </Col>
                        <Col md={3}>
                          <p className="mb-0">
                            <strong>Event Comment</strong>
                          </p>
                          <p>
                            {loading ? <Skeleton /> : streamDetails.comments}
                          </p>
                        </Col>

                        <Col md={3}>
                          <p className="mb-0">
                            <strong>Pay Status</strong>
                          </p>
                          <p>
                            {loading ? <Skeleton /> : streamDetails.payStatus}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                )} */}
              </Card.Body>
            </Card>
            <EditStoriesModel
              show={modalShow}
              onHide={() => setModalShow(false)}
            />

            {!modalShow && <ToastContainer />}
          </>
        )}
      </Container>
    </motion.div>
  );
};

export default ViewStories;
