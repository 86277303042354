import React, { useReducer, useContext, useState } from "react";
import { Store } from "../../states/store";
import { reducer } from "../../states/reducers";
import { Card, Col, Container, Row } from "react-bootstrap";
import { LoadingBox } from "../../components";
import { MessageBox } from "../../components";
import UpdateProfileModel from "./UpdateProfile";
import { motion } from "framer-motion";
import { FaEdit } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";

const ViewProfile = () => {
  const { state } = useContext(Store);
  const { userInfo } = state;
  const [modalShow, setModalShow] = useState(false);
  const [{ loading, error }] = useReducer(reducer, {
    loading: false,
    error: "",
  });

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Get day, month, year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Get hours and minutes
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Determine AM/PM suffix and adjust hours for 12-hour format
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return `${day}-${month}-${year} ${hours}:${minutes}${ampm}`;
  }

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
      exit={{ x: "100%" }}
    >
      <Container fluid className="py-3">
        {loading ? (
          <LoadingBox></LoadingBox>
        ) : error ? (
          <MessageBox variant="danger">{error}</MessageBox>
        ) : (
          <>
            <Card>
              <Card.Header>
                <Card.Title>
                  {loading ? <Skeleton /> : `Admin Profile`}
                </Card.Title>
                <div className="card-tools">
                  <FaEdit
                    style={{ color: "blue" }}
                    onClick={() => setModalShow(true)}
                  />
                </div>
              </Card.Header>
              <Card.Body>
                <Row>
                  <Col md={4}>
                    {loading ? (
                      <Skeleton height={200} />
                    ) : (
                      <img
                        style={{
                          borderRadius: "5px",
                        }}
                        src={userInfo?.avatar}
                        alt=""
                        className="img-fluid"
                        width={"200px"}
                        height={"200px"}
                      />
                    )}
                  </Col>
                </Row>
                <Row>
                  <Col md={4}>
                    <p className="mb-0">
                      <strong>User Name</strong>
                    </p>
                    <p>{loading ? <Skeleton /> : userInfo?.username}</p>
                  </Col>
                  <Col md={4}>
                    <p className="mb-0">
                      <strong>Email</strong>
                    </p>
                    <p>{loading ? <Skeleton /> : userInfo?.email}</p>
                  </Col>
                  <Col md={4}>
                    <p className="mb-0">
                      <strong>Mobile</strong>
                    </p>
                    <p>{loading ? <Skeleton /> : userInfo?.mobile_no}</p>
                  </Col>
                  <Col md={4}>
                    <p className="mb-0">
                      <strong>Role</strong>
                    </p>
                    <p>{loading ? <Skeleton /> : userInfo?.role}</p>
                  </Col>
                  <Col md={4}>
                    <p className="mb-0">
                      <strong>Gender</strong>
                    </p>
                    <p>{loading ? <Skeleton /> : userInfo?.gender}</p>
                  </Col>
                  {userInfo?.dob !== null && (
                    <Col md={4}>
                      <p className="mb-0">
                        <strong>Date of Birth</strong>
                      </p>
                      <p>
                        {loading ? <Skeleton /> : userInfo?.dob.split("T")[0]}
                      </p>
                    </Col>
                  )}

                  <Col md={4}>
                    <p className="mb-0">
                      <strong>Country</strong>
                    </p>
                    <p>{loading ? <Skeleton /> : userInfo?.country}</p>
                  </Col>
                  <Col md={4}>
                    <p className="mb-0">
                      <strong>Created At</strong>
                    </p>
                    <p>
                      {loading ? <Skeleton /> : formatDate(userInfo?.createdAt)}
                    </p>
                  </Col>
                  <Col md={4}>
                    <p className="mb-0">
                      <strong>Last Update</strong>
                    </p>
                    <p>
                      {loading ? <Skeleton /> : formatDate(userInfo?.updatedAt)}
                    </p>
                  </Col>
                </Row>
              </Card.Body>
            </Card>

            <UpdateProfileModel
              show={modalShow}
              onHide={() => setModalShow(false)}
            />
          </>
        )}
      </Container>
    </motion.div>
  );
};

export default ViewProfile;
