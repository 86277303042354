import React, { useEffect, useReducer, useContext, useState } from "react";
import { Store } from "../../states/store";
import { getError } from "../../utils/error.js";
import { reducer } from "../../states/reducers";
import { useNavigate, useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Button, Container, Modal, Form, Spinner } from "react-bootstrap";
import axiosInstance from "../../utils/axiosUtil.js";
import { LoadingBox } from "../../components";

export default function EditGenresModel(props) {
  const navigate = useNavigate();
  const { state } = useContext(Store);
  const { token, genre } = state;
  const { id } = useParams();
  const [load, setLoad] = useState(false);
  const [{ loadingUpdate }, dispatch] = useReducer(reducer, {
    loading: true,
    error: "",
  });
  const [name, setName] = useState("");
  const [image, setImage] = useState("");

  useEffect(() => {
    if (genre.name && genre.name) {
      setName(genre?.name ? genre.name : "");
      setImage(genre.thumbnail ? genre.thumbnail : "");
    }
  }, [genre]);

  const resetForm = () => {
    setName("");
    setImage("");
  };

  const fileHandler = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type.startsWith("image/")) {
        setImage(file);
      } else {
        toast.warning("Please select a valid image file.");
        e.target.value = null;
        return;
      }
    }

    if (e.target.files.length > 1) {
      toast.warning("Please select only one file.");
      e.target.value = null;
    }
  };

  useEffect(() => {}, [id, props.show]);

  const submitHandler = async (e) => {
    e.preventDefault();
    if (!genre) {
      toast.warning("Please fill atleast one fieled");
      return;
    }
    try {
      setLoad(true);
      const formData = new FormData();
      formData.append("name", name);
      const { data } = await axiosInstance.put(
        `/api/admin/genre/${id}`,
        formData,
        {
          headers: {
            authorization: `${token}`,
          },
        }
      );
      if (data.success) {
        toast.success("Genre Updated Succesfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        resetForm();
        setLoad(false);
        setTimeout(() => {
          navigate("/admin/genres");
        }, 1200);
      } else {
        toast.error(data.error.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } catch (err) {
      setLoad(false);
      toast.error(getError(err), {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">Edit Genre</Modal.Title>
      </Modal.Header>
      <Form onSubmit={submitHandler}>
        <Modal.Body>
          <Container className="small-container">
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Genre Description</Form.Label>
              <Form.Control
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="name">
              <Form.Label>Image</Form.Label>
              <Form.Control onChange={(e) => fileHandler(e)} type="file" />
            </Form.Group>
            <ToastContainer />
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="danger" onClick={props.onHide}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            {load ? <Spinner animation="border" size="sm" /> : "Submit"}
          </Button>
          {loadingUpdate && <LoadingBox></LoadingBox>}
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
