import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { Card, Col, Container, Row } from "react-bootstrap";
import { motion } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import { getError } from "../../utils/error";
import axiosInstance from "../../utils/axiosUtil";

const GetQuery = () => {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [query, setQuery] = useState("");

  useEffect(() => {
    getQuery();
  }, [id]);

  const getQuery = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get(`/api/contact/contact/${id}`);
      console.log(data?.contact);
      setQuery(data?.contact);
      setLoading(false);
    } catch (error) {
      toast.error(getError(error), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  function formatDate(dateString) {
    const date = new Date(dateString);

    // Get day, month, year
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();

    // Get hours and minutes
    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    // Determine AM/PM suffix and adjust hours for 12-hour format
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'

    return `${day}-${month}-${year} ${hours}:${minutes}${ampm}`;
  }

  return (
    <motion.div
      initial={{ x: "100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
      exit={{ x: "100%" }}
    >
      <Container fluid className="py-3">
        <Card>
          <Card.Header>
            <Card.Title>{loading ? <Skeleton /> : "Query Details"}</Card.Title>
          </Card.Header>
          <Card.Body>
            <Row>
              <Col md={3}>
                <p className="mb-0">
                  <strong>Name</strong>
                </p>
                <p>{loading ? <Skeleton /> : query?.name}</p>
              </Col>
              <Col md={3}>
                <p className="mb-0">
                  <strong>Created At</strong>
                </p>
                <p>{loading ? <Skeleton /> : formatDate(query?.createdAt)}</p>
              </Col>
              <Col md={3}>
                <p className="mb-0">
                  <strong>Last Update</strong>
                </p>
                <p>{loading ? <Skeleton /> : formatDate(query?.updatedAt)}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="mb-0">
                  <strong>Description</strong>
                </p>
                <p>{loading ? <Skeleton /> : query?.desc}</p>
              </Col>
            </Row>
          </Card.Body>
        </Card>
        <ToastContainer />
      </Container>
    </motion.div>
  );
};

export default GetQuery;
