import React, { useEffect, useState } from "react";
import { Button, Card, Container, Table } from "react-bootstrap";
import { CustomSkeleton } from "../../components";
import { FaEye, FaTrashAlt } from "react-icons/fa";
import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import { getError } from "../../utils/error";
import axiosInstance from "../../utils/axiosUtil";
import Skeleton from "react-loading-skeleton";

export default function Genres() {
  const navigate = useNavigate();
  const [contactUs, setContactUs] = useState([]);
  const [loading, setLoading] = useState(false);
  const [del, setDel] = useState(false);

  useEffect(() => {
    getQueries();
  }, [del]);

  const getQueries = async () => {
    try {
      setLoading(true);
      const { data } = await axiosInstance.get("/api/contact/get-contacts");
      setContactUs(data?.contacts);
      setLoading(false);
    } catch (error) {
      toast.error(getError(error), {
        position: toast.POSITION.TOP_RIGHT,
      });
      setLoading(false);
    }
  };

  const deleteQuery = async (id) => {
    if (
      window.confirm("Are you sure you want to delete this query?") === true
    ) {
      try {
        setDel(true);
        await axiosInstance.delete(`/api/contact/contact-delete/${id}`);
        toast.success("Query Deleted Succesfully.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        setDel(false);
      } catch (error) {
        toast.error(getError(error), {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };

  return (
    <motion.div
      initial={{ x: "-100%" }}
      animate={{ x: "0%" }}
      transition={{ duration: 0.75, ease: "easeInOut" }}
      exit={{ x: "100%" }}
    >
      <Container fluid className="py-3">
        <Card>
          <Card.Header>
            <Card.Title>{loading ? <Skeleton /> : "Queries"}</Card.Title>
          </Card.Header>
          <Card.Body>
            <Table
              style={
                contactUs.length > 1
                  ? { height: "500px", overflowY: "scroll" }
                  : { height: "200px", overflowY: "scroll" }
              }
              responsive
              striped
              bordered
              hover
            >
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {loading ? (
                  <CustomSkeleton resultPerPage={5} column={4} />
                ) : (
                  contactUs.length > 0 &&
                  contactUs?.map((contact, i) => (
                    <tr key={contact?.id} className="odd">
                      <td className="text-center">{i + 1}</td>
                      <td>{contact?.name}</td>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {contact?.email}
                      </td>
                      <td>
                        <Button
                          onClick={() => {
                            navigate(`/admin/get-query/${contact?.id}`);
                          }}
                          type="success"
                          className="btn btn-primary"
                        >
                          <FaEye />
                        </Button>
                        <Button
                          onClick={() => {
                            deleteQuery(contact?.id);
                          }}
                          type="danger"
                          className="btn btn-danger ms-2"
                        >
                          <FaTrashAlt />
                        </Button>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          </Card.Body>
        </Card>
        <ToastContainer />
      </Container>
    </motion.div>
  );
}
